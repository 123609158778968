const AQI_LEVELS = [
  {
    title: 'Хороший рівень',
    descr: 'Якість повітря хороша і не становить загрози здоров’ю.',
    color: '#69b34c',
    min: 0,
    max: 12,
  },
  {
    title: 'Середній рівень',
    descr:
      'Діапазон помірний і якість повітря прийнятна. Може викликати незначний дискомфорт при диханні у чутливих людей.',
    color: '#acb334',
    min: 12.1,
    max: 35.4,
  },
  {
    title: 'Нездоровий рівень для чутливих груп',
    descr:
      'Може спричинити дискомфорт при диханні у людей із захворюваннями легенів, таких як астма, а також у людей з серцевими захворюваннями, дітей і літніх людей.',
    color: '#fab733',
    min: 35.5,
    max: 55.4,
  },
  {
    title: 'Нездоровий рівень',
    descr:
      'Нездорова якість повітря, при якій люди починають відчувати утруднення дихання.',
    color: '#ff8e15',
    min: 55.5,
    max: 150.4,
  },
  {
    title: 'Дуже нездоровий рівень',
    descr:
      'Якість повітря в цьому діапазоні дуже нездорова. Її відчують усі люди.',
    color: '#ff4e11',
    min: 150.5,
    max: 250.4,
  },
  {
    title: 'Рівень небезпеки',
    descr:
      'Небезпечна якість повітря. Усі можуть відчувати серйозні симптоми, такі як дискомфорт при диханні, задуха, подразнення дихальних шляхів тощо.',
    color: '#ff0d0d',
    min: 250.5,
    max: 500.4,
  },
]

export default AQI_LEVELS
