const filterList = (list, pmKey) =>
  list.filter(({ node }) => node.phenomenon === pmKey)

export const createDayListByPm = (list, pmKey) => {
  const newList = filterList(list, pmKey)

  return newList
    .sort((a, b) => new Date(a.node.logged_at) - new Date(b.node.logged_at))
    .map(({ node }) => node.value)
}

export const createMonthListByPm = (list, pmKey) => {
  const newList = filterList(list, pmKey)

  const obj = newList.reduce((prev, { node }) => {
    const monthId = new Date(node.logged_at).getMonth()

    const prevMonth = prev[monthId] || 0

    return { ...prev, [monthId]: prevMonth + node.value }
  }, {})

  return Object.values(obj)
}

export const createYearListByPm = (list, pmKey) => {
  const newList = filterList(list, pmKey)
  return newList.reduce((prev, { node }) => [prev[0] + node.value], [0])
}
