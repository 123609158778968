import React from 'react'
import { Container } from 'react-bootstrap'

import * as s from './Phenomenon.module.scss'
import FAQ from './components/FAQ'
import ChartPm from './components/ChartPm'
import AQI from './components/AQI'

const Phenomenon = ({ list }) => {
  return (
    <Container as="section" className={s.phenomenon}>
      <AQI list={list.filter(({ node }) => node.phenomenon === 'pm25')} />
      <ChartPm list={list} />
      <FAQ />
    </Container>
  )
}

export default Phenomenon
