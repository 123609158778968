const DATA = [
  {
    title: 'Що таке AQI?',
    descr:
      "AQI - air quality index - індекс якості повітря, ця абревіатура використовується практично в усіх країнах для інформування громадськості про рівень забруднення повітря. При перевищенні індексу якості повітря величини 100, населення отримує попередження про можливий вплив на здоров'я.",
  },
  {
    title: 'Як вирахувати AQI?',
    descr:
      "AQI - air quality index - індекс якості повітря, ця абревіатура використовується практично в усіх країнах для інформування громадськості про рівень забруднення повітря. При перевищенні індексу якості повітря величини 100, населення отримує попередження про можливий вплив на здоров'я.",
  },
  {
    title: 'Що таке PM?',
    descr:
      "AQI - air quality index - індекс якості повітря, ця абревіатура використовується практично в усіх країнах для інформування громадськості про рівень забруднення повітря. При перевищенні індексу якості повітря величини 100, населення отримує попередження про можливий вплив на здоров'я.",
  },
  {
    title: 'Які бувають PM?',
    descr:
      "AQI - air quality index - індекс якості повітря, ця абревіатура використовується практично в усіх країнах для інформування громадськості про рівень забруднення повітря. При перевищенні індексу якості повітря величини 100, населення отримує попередження про можливий вплив на здоров'я.",
  },
]

export default DATA
