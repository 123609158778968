import React from 'react'
import { graphql } from 'gatsby'

import SEO from '~components/seo'
import Phenomenon from '~containers/Phenomenon'

export const Head = () => <SEO title="AQI" />

const PhenomenonPage = ({ data }) => (
  <Phenomenon list={data.allMongodbEcoQualityAqiProcessed.edges} />
)

export const query = graphql`
  {
    allMongodbEcoQualityAqiProcessed {
      edges {
        node {
          logged_at
          mongodb_id
          value
          phenomenon
        }
      }
    }
  }
`

export default PhenomenonPage
