import React from 'react'
import { Accordion } from 'react-bootstrap'
import DATA from './constants'

import * as s from './FAQ.module.scss'

const FAQ = () => {
  return (
    <section className={s.faq}>
      <h2 className={s.faq_title}>Що варто знати:</h2>

      <Accordion>
        {DATA.map(({ title, descr }) => (
          <Accordion.Item key={title} eventKey={title}>
            <Accordion.Header>{title}</Accordion.Header>
            <Accordion.Body>{descr}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </section>
  )
}

export default FAQ
