import React, { useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'

import * as s from './AQI.module.scss'
import AQI_LEVELS from './constants'

const AQI = ({ list }) => {
  const [selectedDate, setSelectedDate] = useState(new Date('04/11/2022'))

  const parsedDate = useMemo(
    () =>
      selectedDate.toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
    [selectedDate]
  )

  const currentAqi = useMemo(() => {
    const selectedNode = list.find(({ node }) => {
      return (
        new Date(node.logged_at).setHours(0, 0, 0, 0) ===
        selectedDate.setHours(0, 0, 0, 0)
      )
    })

    if (!selectedNode) return undefined

    const { value } = selectedNode.node

    return {
      ...AQI_LEVELS.find(({ min, max }) => value >= min && value <= max),
      value,
    }
  }, [list, selectedDate])

  return (
    <section className={s.aqi}>
      <div className={s.aqi_content}>
        <h3 className={s.aqi_contentTitle}>
          {currentAqi ? currentAqi.title : 'Недостатньо даних'}
        </h3>
        <span className={s.aqi_contentDescr}>
          {currentAqi && currentAqi.descr}
        </span>

        <div className={s.aqi_datepicker}>
          <span className={s.aqi_disclaimer}>Оберіть дату:</span>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            className="form-control"
          />
        </div>

        <span className={s.aqi_disclaimer}>
          The air quality index (AQI) calculated by the formula{' '}
          <a
            href="https://www.airnow.gov/?city=Lviv&country=UKR"
            target="_blank"
            rel="noreferrer"
          >
            NowCast (US EPA)
          </a>{' '}
          for the main air pollutant - fine dust of fraction PM2.5.
        </span>
      </div>

      <div className={s.aqi_ill}>
        <h2 className={s.aqi_title}>Індекс якості повітря AQI</h2>

        <span
          style={{
            backgroundColor: currentAqi ? currentAqi.color : '#adb5bd',
          }}
          className={s.aqi_item}
        >
          {currentAqi ? currentAqi.value : 'n/a'}
        </span>

        <h4 className={s.aqi_illTitle}>
          Індекс якості повітря у Франківському районі Львова {parsedDate}
        </h4>
      </div>
    </section>
  )
}

export default AQI
