export const PERIOD_KEYS = ['day', 'month', 'year']

export const PERIOD_DATA = {
  day: { label: 'День' },
  month: { label: 'Місяць' },
  year: { label: 'Рік' },
}

export const MONTHS = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень',
]
