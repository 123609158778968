import React, { useEffect, useMemo, useState } from 'react'
import { Button, Nav } from 'react-bootstrap'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Chart } from 'chart.js/auto'
import {
  createDayListByPm,
  createMonthListByPm,
  createYearListByPm,
} from '~utils/ChartData'
import { PERIOD_KEYS, PERIOD_DATA, MONTHS } from './constants'

import * as s from './ChartPm.module.scss'

const ChartPm = ({ list }) => {
  const [periodKey, setPeriodKey] = useState(PERIOD_KEYS[0])
  const [subKey, setSubKey] = useState(0)

  const newData = useMemo(() => {
    if (periodKey === PERIOD_KEYS[0]) {
      const newList = list
        .filter(({ node }) => new Date(node.logged_at).getMonth() === subKey)
        .sort((a, b) => new Date(a.node.logged_at) - new Date(b.node.logged_at))

      return {
        labels: newList
          .filter(({ node }) => node.phenomenon === 'pm1')
          .map(({ node }) =>
            new Date(node.logged_at).toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
            })
          ),
        pm1: createDayListByPm(newList, 'pm1'),
        pm10: createDayListByPm(newList, 'pm10'),
        pm25: createDayListByPm(newList, 'pm25'),
      }
    }

    if (periodKey === PERIOD_KEYS[1]) {
      return {
        labels: MONTHS,
        pm1: createMonthListByPm(list, 'pm1'),
        pm10: createMonthListByPm(list, 'pm10'),
        pm25: createMonthListByPm(list, 'pm25'),
      }
    }

    if (periodKey === PERIOD_KEYS[2]) {
      return {
        labels: ['2022'],
        pm1: createYearListByPm(list, 'pm1'),
        pm10: createYearListByPm(list, 'pm10'),
        pm25: createYearListByPm(list, 'pm25'),
      }
    }

    return null
  }, [periodKey, subKey])

  useEffect(() => {
    const chart = new Chart(document.getElementById('chart_pm'), {
      type: 'bar',

      data: {
        labels: newData.labels,
        datasets: [
          {
            label: 'PM1',
            data: newData.pm1,
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgb(255, 159, 64)',
            borderWidth: 1,
          },
          {
            label: 'PM10',
            data: newData.pm10,
            backgroundColor: 'rgba(255, 205, 86, 0.2)',
            borderColor: 'rgb(255, 205, 86)',
            borderWidth: 1,
          },
          {
            label: 'PM25',
            data: newData.pm25,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 1,
          },
        ],
      },
    })

    return () => {
      chart.destroy()
    }
  }, [newData])

  const handleSelectTab = (key) => {
    setPeriodKey(key)
    setSubKey(0)
  }

  return (
    <section className={s.chartpm}>
      <h2 className={s.chartpm_title}>
        Графік кількості пилових часток у повітрі
      </h2>

      <Nav
        defaultActiveKey={periodKey}
        variant="tabs"
        onSelect={handleSelectTab}
      >
        {PERIOD_KEYS.map((key) => (
          <Nav.Item key={key}>
            <Nav.Link eventKey={key}>{PERIOD_DATA[key].label}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      {periodKey === PERIOD_KEYS[0] && (
        <div className={s.chartpm_buttons}>
          {MONTHS.map((key, i) => (
            <Button
              variant="outline-primary"
              active={subKey === i}
              onClick={() => setSubKey(i)}
              key={key}
            >
              {key}
            </Button>
          ))}
        </div>
      )}

      <div className={s.chartpm_chartWrapper}>
        <canvas
          className={s.chartpm_chart}
          width={1160}
          height={580}
          id="chart_pm"
        />
      </div>
    </section>
  )
}

export default ChartPm
